import path from 'path';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { rates as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    SEO,
} from '@brandedholdings/react-components';

class Rates extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);


        return (
            <PageWrapper>
                <SEO post={ post } />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>Rates &amp; Fees</h1>
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout">
                        <div className="layout-content">
                            
                            <p>{siteMeta.nameShort} is not a lender or a loan broker. We do not make loan decisions. We are a loan connect system. We connect consumers who are looking for payday loans or small installment loans to lenders who may be able to help by offering a loan to qualified consumers.</p>

                            <p>If after registering on the {siteMeta.nameShort} website, you are connected with a lender, the lender will make you an offer which will include the loan amount, the fees and APR, as well as the terms of repayment. Please make yourself aware of all terms of the loan, including the consequences if you do not make the payment on time.</p>

                            <p>You are under no obligation to accept the terms of the loan if you do not wish to. You are only obligated once you have electronically "signed" for the loan.</p>

                            <p>Your repayment will be automatically deducted from your bank account on the pre-scheduled due date. If you do not repay the loan in full, including all fees and interest charges, there are consequences of which you should be aware before agreeing to the loan. If repayment will be a problem, do not accept the loan.</p>

                            <p>All questions regarding repayment and its consequences should be directed to your lender. We work with numerous lenders that each have their own sets of rules for their loans.</p>

                            <p>If you do not repay the loan, including fees, on time, typical actions taken by lenders include turning your account over to a collection agency, reporting to a credit score agency, an offer to renew, which will add additional fees and charges to your account balance, and/or legal action.</p>

                            <p>Late Payments of loans may result in additional fees or collection activities, or both. Each lender has their own terms and conditions, please review their policies for further information. Nonpayment of credit could result in collection activities. Each lender has their own terms and conditions, please review their policies for further information. Every lender has its own renewal policy, which may differ from lender to lender. Please review your lender’s renewal policy.</p>

                            <p>Contact your lender with any questions or problems with your loan.</p>

                            <p>{siteMeta.nameShort} does not charge consumers for its loan connecting services, so registering online is free!</p>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

Rates.childContextTypes = {
    siteMeta: PropTypes.object
};

export default Rates;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;